<template>
  <div>
    <!-- <AttachmentComponent></AttachmentComponent>
    <ActionPlanComponent :query="query" @update-ap-query="printQuery"/> -->
    <div class="mb-5">
      {{ this.areas }}
    </div>
    <HierarchyComponent size="280px" mode="edit" selection="single" type="originated"
      @returned-areas="setArea" :selectedAreas="areas">
    </HierarchyComponent>
  </div>
</template>

<script>
// import ActionPlanComponent from "../components/ActionPlanComponent.vue";
// import AttachmentComponent from "../components/AttachmentComponent.vue"
import HierarchyComponent from '../components/HierarchyComponent.vue';

export default {
  components: {
    // ActionPlanComponent,
    // AttachmentComponent
    HierarchyComponent
  },
  data() {
    return {
      areas: null
      // query: [
      //   {

      //           "id": 504,
      //           "status": 1,
      //           "title": "novo action plan",
      //           "progress": "On time",
      //           "description": "novo action plan",
      //           "hierarchy": [
      //               {
      //                   "type": "originated",
      //                   "id": 981
      //               }
      //           ],
      //           "expectedCompletionDate": "2024-05-24T03:00:00Z",
      //           "actualCompletionDate": null,
      //           "type": "tatic",
      //           "owner": [
      //               "gabriel.callado@btgpactual.com"
      //           ],
      //           "registeredBy": "gabriel.callado@btgpactual.com",
      //           "pro3Id": [
      //               111
      //           ],
      //           "attachment": null,
      //           "logs": [
      //               {
      //                   "date": "2024-05-23T10:18:10.944648-03:00",
      //                   "type": 1,
      //                   "user": "dl-oprisk@btgpactual.com",
      //                   "changelog": null
      //               }
      //           ],
      //           "fup": [
      //               {
      //                   "text": "aaaaaaa",
      //                   "user": "gabriel.callado@btgpactual.com",
      //                   "date": "2024-05-23T12:52:30.553Z"
      //               }
      //           ],
      //           "updatedTimestamp": null,
      //           "submittedTimestamp": "2024-05-23T10:18:10.936757939-03:00",
      //           "udpatedBy": null,
      //           "submittedBy": "dl-oprisk@btgpactual.com",
      //           "extraFields": null,
      //           "integrationFrom": {
      //               "application": "storm",
      //               "mode": "form"
      //           }
      //   },
      // ],
    };
  },
  methods: {
    setArea(value) {
      console.log(value)
      this.areas = value
    },
    // printQuery(event){
    //   console.log(event)
    // }
  },
};
</script>
<style></style>
