<template>
    <div v-if="selectionLocal == 'single'">

        <div v-if="selectedChipLocal.length > 0 && this.verifyAreas()" style="
                  margin-left: 5px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                  font-size: 0.8em;
                ">
            <b>{{ this.$langGetValue('previous') }}:</b>
        </div>

        <Chip class="secondaryChip" v-if="selectedChipOld.length > 0" :label="selectedChipOld[0].label"
            v-tooltip.top="{ value: selectedChipOld[0].path }" :style="{ marginLeft: '4px' }" />

        <div v-if="modeLocal == 'edit' || modeLocal == 'search' || modeLocal == 'entuba'">

            <div v-if="selectedChipLocal.length > 0 && this.verifyAreas()" style="
                  margin-left: 5px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                  font-size: 0.8em;
                ">
                <b>{{ this.$langGetValue('modified') }}:</b>
            </div>

            <div v-if="selectedChipLocal.length > 0" style="margin: 5px;">
                <Chip class="secondaryChip" :label="selectedChipLocal[0].label" :style="{ marginLeft: '4px' }" />
            </div>
            <Tree id="hierarchy-tree" :class="{ 'invalid': isInvalidLocal, 'customTreeHierarchy': true }"
                v-model:selectionKeys="selectedAreasModel" :value="hierarchy" :expanded-keys="expandedKeysLocal"
                :scrollHeight="this.sizeLocal != undefined ? this.sizeLocal : '200px'" selectionMode="single" :filter="true"
                style="margin-top: 5px;" filterMode="lenient" @node-select="chipSelectionSingle" />
        </div>
    </div>
    <div v-if="selectionLocal == 'multiple'">

        <div v-if="selectedChipLocal.length > 0 && this.verifyAreas()" style="
                  margin-left: 5px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                  font-size: 0.8em;
                ">
            <b>{{ this.$langGetValue('previous') }}:</b>
        </div>

        <div class="flex" style="gap: 4px">
            <Chip class="secondaryChip" v-for="selected in selectedChipOld" :key="selected" :label="selected.label"
                v-tooltip.top="{ value: selected.path }" />
        </div>
        <div v-if="modeLocal == 'edit' || modeLocal == 'search' || modeLocal == 'entuba'">
            <div v-if="selectedChipLocal.length > 0 && this.verifyAreas()" style="
                  margin-left: 5px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                  font-size: 0.8em;
                ">
                <b>{{ this.$langGetValue('modified') }}:</b>
            </div>
            <div style="margin: 5px">
                <div class="flex" style="gap: 4px">
                    <Chip icon="pi pi-trash" v-if="selectedChipLocal.length > 0" style="
                        background-color: transparent;
                        color: #eb3d47;
                        padding: 0px;
                        font-weight: 600;
                        cursor: pointer;
                      " :label="this.$langGetValue('clean')" @click="removeAllChips" />
                    <Chip class="secondaryChip" v-for="selected in selectedChipLocal" :key="selected"
                        :label="selected.label" removable @remove="removeChip(selected)" />
                </div>
            </div>
            <Tree id="hierarchy-tree" :class="{ 'invalid': isInvalidLocal, 'customTreeHierarchy': true }"
                v-model:selectionKeys="selectedAreasModel" :metaKeySelection="false" :expanded-keys="expandedKeysLocal"
                :value="hierarchy" :scrollHeight="this.sizeLocal != undefined ? this.sizeLocal : '200px'"
                selectionMode="multiple" :filter="true" filterMode="lenient" @node-select="chipSelectionMultiple"
                @node-unselect="chipUnselectionMultiple" />

            <div v-if="modeLocal == 'search'" style="margin: 5px">
                <div class="flex" style="gap: 4px">
                    <Chip icon="pi pi-trash" v-if="selectedChipLocal.length > 0" style="
                        background-color: transparent;
                        color: #eb3d47;
                        padding: 0px;
                        font-weight: 600;
                        cursor: pointer;
                      " :label="this.$langGetValue('clean')" @click="removeAllChips" />
                    <Chip class="secondaryChip" v-for="selected in selectedChipLocal" :key="selected"
                        :label="selected.label" removable @remove="removeChip(selected)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import IncidentService from "../service/IncidentService.js";

export default {
    props: {
        selectedAreas: Array,
        isInvalid: Boolean,
        selection: String,
        mode: String,
        type: String,
        size: String,
    },
    setup(props) {
        const selectedAreasLocal = ref(props.selectedAreas);
        const isInvalidLocal = ref(props.isInvalid)
        const selectionLocal = ref(props.selection)
        const modeLocal = ref(props.mode)
        const typeLocal = ref(props.type)
        const sizeLocal = ref(props.size)
        return {
            selectedAreasLocal,
            isInvalidLocal,
            selectionLocal,
            modeLocal,
            typeLocal,
            sizeLocal
        };
    },
    data() {
        return {
            hierarchy: IncidentService.incidentFields.hierarchy.data,
            expandedKeysLocal: {},
            hierarchyArray: [],
            intervalExpandKeys: null,
            lastFilterValue: "",
            selectedChipLocal: [],
            selectedAreasModel: {},
            selectedChipOld: [],
        };
    },
    mounted() {
        this.intervalExpandKeys = setInterval(() => this.expandKeysLocal(), 100);
        this.areaHierarchy = IncidentService.incidentFields.hierarchy.data;
        this.createArrayWithPath()
        this.mountComponent()
    },
    watch: {
        // isInvalidLocal() {
        //     console.log(this.isInvalidLocal)
        // }
    },
    unmounted() {
        clearInterval(this.intervalExpandKeys)
    },
    methods: {
        mountComponent() {
            if (this.verifyAreas()) {
                this.selectedAreasLocal.forEach((tempArea) => {
                    const area = this.hierarchyArray.find(object => object.id === tempArea.id);
                    if (area) {
                        this.selectedChipOld.push({
                            label: area.label,
                            path: area.path,
                            id: area.id
                        })

                        this.selectedAreasModel[parseInt(area.id, 10)] = true

                        if (this.modeLocal == 'entuba') {
                            this.selectedChipLocal.push({
                                label: area.label,
                                path: area.path,
                                id: area.id
                            })
                        }
                    }
                })
            }
        },
        verifyAreas() {
            if (this.selectedAreasLocal != undefined && this.selectedAreasLocal != null && this.selectedAreas.length > 0) {
                return true
            }
            return false
        },
        returnAreas() {
            setTimeout(() => {
                let tempArray = []
                Object.keys(this.selectedAreasModel).forEach(key => {
                    tempArray.push({
                        type: this.typeLocal,
                        id: parseInt(key)
                    })
                });

                this.$emit('returned-areas', tempArray);
            }, 200)
        },
        createArrayWithPath() {
            function visitNodes(tree, path = []) {
                if (!tree) {
                    return [];
                }

                path.push(tree.label);
                const info = {
                    id: tree.id,
                    label: tree.label,
                    path: path.join(" -> "),
                };

                const result = [info];

                if (tree.children && tree.children.length > 0) {
                    for (const children of tree.children) {
                        result.push(...visitNodes(children, path.slice()));
                    }
                }

                return result;
            }

            for (let i = 0; i < this.areaHierarchy.length; i++) {
                this.hierarchyArray = this.hierarchyArray.concat(
                    visitNodes(this.areaHierarchy[i])
                );
            }
        },
        //
        chipSelectionSingle(node) {
            const area = this.hierarchyArray.find(object => object.id === node.id);

            if (area) {
                this.selectedChipLocal = [{
                    label: area.label,
                    path: area.path,
                    id: area.id
                }]
            }

            this.returnAreas()
        },
        //
        chipSelectionMultiple(node) {
            if (this.selectedChipLocal.length == 0) {
                this.selectedChipLocal = [...this.selectedChipOld]

                setTimeout(() => {
                    this.selectedChipOld.forEach((area) => {
                        if (!(area.id in this.selectedAreasModel)) {
                            this.selectedAreasModel[parseInt(area.id, 10)] = true
                        }
                    })
                }, 200)
            }

            if (!this.selectedChipLocal.some(obj => obj.id === node.id)) {
                const area = this.hierarchyArray.find(object => object.id === node.id);
                if (area) {
                    this.selectedChipLocal.push({
                        label: area.label,
                        path: area.path,
                        id: area.id
                    })
                }
            }

            this.returnAreas()
        },
        chipUnselectionMultiple(node) {
            this.selectedChipLocal.forEach((chip, index) => {
                if (chip.id == node.id) {
                    this.selectedChipLocal.splice(index, 1);
                }
            });

            this.returnAreas()
        },
        removeAllChips() {
            this.selectedAreasModel = [];
            this.selectedChipLocal = [];
            this.returnAreas()
        },
        removeChip(node) {
            let chave = node.id;

            delete this.selectedAreasModel[chave];

            this.chipUnselectionMultiple(node);
            this.returnAreas()
        },
        // filter
        expandKeysLocal() {
            if (this.modeLocal == 'view') {
                return
            }

            let el = document.querySelector(
                "#hierarchy-tree > div.p-tree-filter-container > input"
            );
            if (this.lastFilterValue == el.value) {
                return;
            }
            this.lastFilterValue = el.value;
            if (el.value == "") {
                this.expandedKeysLocal = {};
            } else {
                this.expandAllKeysLocal();
            }
        },
        expandAllKeysLocal() {
            for (let node of this.hierarchy) {
                this.expandNodeLocal(node);
            }

            this.expandedKeysLocal = { ...this.expandedKeysLocal };
        },
        expandNodeLocal(node) {
            if (node.children && node.children.length && !node.label.includes(this.lastFilterValue.toUpperCase())) {
                this.expandedKeysLocal[node.key] = true;

                for (let child of node.children) {
                    this.expandNodeLocal(child);
                }
            }
        },
    },
};

</script>

<style>
.customTreeHierarchy .p-tree-container .p-treenode {
    border-style: none none none solid;
    border-color: #ced4da;
}

.invalid {
    border: 1px solid red;
}
</style>